import Accordion from "react-bootstrap/Accordion";
import styles from "./FooterMobile.module.scss";
import Icon from "../../Layout/icons/icons";
import { Link } from "react-router-dom";

function FooterMobile({ usefulLink, quickLink }) {
  return (
    <Accordion className={styles.footerMobileMain} defaultActiveKey="0">
      <Accordion.Item eventKey="0">
        <Accordion.Header>
          <h5 className="h5">Quick Links</h5>
          <Icon icon="slider-arrow" size={21} color="#FFFFFF" />
        </Accordion.Header>
        <Accordion.Body>
          <ul>
            {quickLink?.map((obj, idx) => (
              <li key={idx}>
                <Link
                  to={`/${obj?.link}`}
                  target={obj?.newTab === "true" ? "_blank" : "_self"}
                >
                  <button className={styles.footerListBtn}>{obj?.title}</button>
                </Link>
              </li>
            ))}
          </ul>
        </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="1">
        <Accordion.Header>
          <h5 className="h5">Useful Links</h5>
          <Icon icon="slider-arrow" size={21} color="#FFFFFF" />
        </Accordion.Header>
        <Accordion.Body>
          <ul>
            {usefulLink?.map((obj, idx) => (
              <li key={idx}>
                <Link
                  to={`/${obj?.link}`}
                  target={obj?.newTab === "true" ? "_blank" : "_self"}
                >
                  <button className={styles.footerListBtn}>{obj?.title}</button>
                </Link>
              </li>
            ))}
          </ul>
        </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="2">
        <Accordion.Header>
          {" "}
          <h5 className="h5">Contact info</h5>
          <Icon icon="slider-arrow" size={21} color="#FFFFFF" />
        </Accordion.Header>
        <Accordion.Body>
          <div className={styles.phoneWrapper}>
            <a href="tel:+91 6576 653333">+91 657 665 3333</a>
            <a href="mailto:info@xlri.ac.in">info@xlri.ac.in</a>
          </div>
          <div className={styles.addressWrapper}>
            <p className="p-18">
              XLRI <br></br>C. H. Area (East)<br></br> Jamshedpur - 831001
              <br></br> Jharkhand, India.
            </p>
            <Link className="linkWrapper" to="/reaching-xlri">
              <span className="a-18">Get Direction</span>
              <Icon icon="arrow-right-with-cut" size={10} color="#B2C950" />
            </Link>
          </div>
          <div className={styles.socialmediaWrapper}>
            <h5 className="h5">Follow us</h5>
            <ul className={styles.socialmediaicons}>
              <li>
                <a
                  href="https://www.facebook.com/XLRI.Jamshedpur"
                  rel="noreferrer"
                  target="_blank"
                >
                  <Icon icon="facebook" size={17} color="#FFFFFF" />
                </a>
              </li>
              <li>
                <a
                  href="https://twitter.com/xlrijamshedpur"
                  rel="noreferrer"
                  target="_blank"
                >
                  <Icon icon="twitter" size={17} color="#FFFFFF" />
                </a>
              </li>
              <li>
                <a
                  href="https://www.instagram.com/xlrijamshedpur/"
                  rel="noreferrer"
                  target="_blank"
                >
                  <Icon icon="instagram" size={17} color="#FFFFFF" />
                </a>
              </li>
              <li>
                <a
                  href="https://www.linkedin.com/edu/school?id=20387&trk=edu-cp-title"
                  rel="noreferrer"
                  target="_blank"
                >
                  <Icon icon="linkedin" size={17} color="#FFFFFF" />
                </a>
              </li>
              <li>
                <a
                  href="https://www.youtube.com/user/XLRIBRAND"
                  rel="noreferrer"
                  target="_blank"
                >
                  <Icon icon="youtube" size={17} color="#FFFFFF" />
                </a>
              </li>
            </ul>
          </div>
        </Accordion.Body>
      </Accordion.Item>
    </Accordion>
  );
}

export default FooterMobile;
